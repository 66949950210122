/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';

import Container from 'components/container';

import 'stylesheets/openAPI.scss';

function OpenAPI() {
  function swapState(e) {
    e.preventDefault();
    e.target.className === 'openAPI-menu-spread'
      ? (e.target.className = 'openAPI-menu')
      : (e.target.className = 'openAPI-menu-spread');
  }

  function swapMobileState() {
    const mobileMenuStateCheck = document.querySelector('.openAPI-mobile-menu-container');
    mobileMenuStateCheck.className === 'openAPI-mobile-menu-container mobile-menu-fold'
      ? (mobileMenuStateCheck.className = 'openAPI-mobile-menu-container')
      : (mobileMenuStateCheck.className = 'openAPI-mobile-menu-container mobile-menu-fold');
  }

  useEffect(() => {
    const menuHeight = document.querySelectorAll('.openAPI-menu-depth');
    for (let i = 0; i < menuHeight.length; i += 1) {
      const menuNumber = menuHeight[i].querySelectorAll('.openAPI-menu-small');
      menuHeight[i].style.cssText = `max-height: ${menuNumber.length * 40}px`;
    }
  }, []);

  return (
    <Container paddingTop={0} backgroundColor="#f2f2f7">
      <div className="openAPI-wrapper">
        <div className="openAPI-container">
          <div className="openAPI-mobile-menu-container mobile-menu-fold">
            <div className="openAPI-mobile-menu" onClick={swapMobileState}>
              API NAME
              <img
                alt="arrow"
                className="openAPI-mobile-menu-arrow"
                src="/icon/openapi-arrow-bottom.png"
              />
            </div>
            <div className="openAPI-mobile-menu-spread">
              <div className="openAPI-mobile-menu-spread-label">label</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-label">label</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-label">label</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
              <div className="openAPI-mobile-menu-spread-menu">API menu name</div>
            </div>
          </div>
          <div className="openAPI-menu-container">
            <div className="openAPI-menuset">
              <div className="openAPI-label">Label</div>
              <div className="openAPI-menu" onClick={swapState}>
                <img alt="arrow" src="/icon/openapi-arrow-bottom.png" />
                API NAME
              </div>
              <div className="openAPI-menu-depth">
                <div className="openAPI-menu-small">
                  API menu
                  <div className="openAPI-menu-small-type-get">GET</div>
                </div>
                <div className="openAPI-menu-small">
                  API menu
                  <div className="openAPI-menu-small-type-post">POST</div>
                </div>
                <div className="openAPI-menu-small">
                  API menu
                  <div className="openAPI-menu-small-type-delete">DELETE</div>
                </div>
                <div className="openAPI-menu-small">
                  API menu
                  <div className="openAPI-menu-small-type-put">PUT</div>
                </div>
              </div>
              <div className="openAPI-menu" onClick={swapState}>
                <img alt="arrow" src="/icon/openapi-arrow-bottom.png" />
                API NAME
              </div>
              <div className="openAPI-menu-depth">
                <div className="openAPI-menu-small">API menu</div>
                <div className="openAPI-menu-small">API menu</div>
                <div className="openAPI-menu-small">API menu</div>
              </div>
            </div>
            <div />
          </div>
          <div className="openAPI-content-container">
            <h2>API NAME</h2>
            <div className="openAPI-content-script">
              가는 곳이 대중을 영락과 천자만홍이 사는가 아름답고 천고에 봄바람이다. 평화스러운
              구하지 고행을 가는 든 끓는다. 위하여 미인을 시들어 천자만홍이 끓는다. 심장은 작고
              위하여 목숨이 우리의 황금시대다.
            </div>
            <div className="openAPI-content-type-wrapper">
              <div className="openAPI-content-type-get">GET</div>
              https://api.tcall.io/v1j/apiname
            </div>
            <div className="openAPI-table-container-request-params">
              <div className="openAPI-table-title">Request Parameter</div>
              <div className="openAPI-table">
                <div className="openAPI-table-head">
                  <div className="table-cell left fg2">Name</div>
                  <div className="table-cell left fg4">Info</div>
                  <div className="table-cell left fg1">Type</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    currency
                    <span className="openAPI-table-require">*필수</span>
                  </div>
                  <div className="table-cell left fg4">Currency 코드</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    state
                    <span className="openAPI-table-require">*필수</span>
                  </div>
                  <div className="table-cell left fg4">
                    출금 상태
                    <br />
                    - submitting : 처리 중
                    <br />
                    - submitted : 처리 완료
                    <br />
                    - almost_accepted : 출금대기중
                    <br />
                    - rejected : 거부
                    <br />
                    - accepted : 승인됨
                    <br />
                    - processing : 처리 중
                    <br />
                    - done : 완료
                    <br />
                    - canceled : 취소됨
                    <br />
                  </div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    uuids
                    <span className="openAPI-table-require">*필수</span>
                  </div>
                  <div className="table-cell left fg4">출금 UUID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    txids
                    <span className="openAPI-table-require">*필수</span>
                  </div>
                  <div className="table-cell left fg4">출금 TXID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">limit</div>
                  <div className="table-cell left fg4">개수 제한 (default: 100, max: 100)</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">page</div>
                  <div className="table-cell left fg4">페이지 수, default: 1</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">order_by</div>
                  <div className="table-cell left fg4">
                    정렬
                    <br />
                    - asc : 오름차순
                    <br />
                    - desc : 내림차순 (default)
                    <br />
                  </div>
                  <div className="table-cell left fg1">Number</div>
                </div>
              </div>
            </div>
            <div className="openAPI-table-container-response">
              <div className="openAPI-table-title">Response</div>
              <div className="openAPI-table">
                <div className="openAPI-table-head">
                  <div className="table-cell left fg2">Name</div>
                  <div className="table-cell left fg4">Info</div>
                  <div className="table-cell left fg1">Type</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">currency</div>
                  <div className="table-cell left fg4">Currency 코드</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">state</div>
                  <div className="table-cell left fg4">
                    출금 상태
                    <br />
                    - submitting : 처리 중
                    <br />
                    - submitted : 처리 완료
                    <br />
                    - almost_accepted : 출금대기중
                    <br />
                    - rejected : 거부
                    <br />
                    - accepted : 승인됨
                    <br />
                    - processing : 처리 중
                    <br />
                    - done : 완료
                    <br />
                    - canceled : 취소됨
                    <br />
                  </div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">uuids</div>
                  <div className="table-cell left fg4">출금 UUID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">txids</div>
                  <div className="table-cell left fg4">출금 TXID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">limit</div>
                  <div className="table-cell left fg4">개수 제한 (default: 100, max: 100)</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">page</div>
                  <div className="table-cell left fg4">페이지 수, default: 1</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">order_by</div>
                  <div className="table-cell left fg4">
                    정렬
                    <br />
                    - asc : 오름차순
                    <br />
                    - desc : 내림차순 (default)
                    <br />
                  </div>
                  <div className="table-cell left fg1">Number</div>
                </div>
              </div>
            </div>
            <div className="openAPI-table-container-query-params">
              <div className="openAPI-table-title">Query Parameters</div>
              <div className="openAPI-table">
                <div className="openAPI-table-head">
                  <div className="table-cell left fg2">Name</div>
                  <div className="table-cell left fg4">Info</div>
                  <div className="table-cell left fg1">Type</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">currency</div>
                  <div className="table-cell left fg4">Currency 코드</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">state</div>
                  <div className="table-cell left fg4">
                    출금 상태
                    <br />
                    - submitting : 처리 중
                    <br />
                    - submitted : 처리 완료
                    <br />
                    - almost_accepted : 출금대기중
                    <br />
                    - rejected : 거부
                    <br />
                    - accepted : 승인됨
                    <br />
                    - processing : 처리 중
                    <br />
                    - done : 완료
                    <br />
                    - canceled : 취소됨
                    <br />
                  </div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">uuids</div>
                  <div className="table-cell left fg4">출금 UUID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">txids</div>
                  <div className="table-cell left fg4">출금 TXID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">limit</div>
                  <div className="table-cell left fg4">개수 제한 (default: 100, max: 100)</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">page</div>
                  <div className="table-cell left fg4">페이지 수, default: 1</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">order_by</div>
                  <div className="table-cell left fg4">
                    정렬
                    <br />
                    - asc : 오름차순
                    <br />
                    - desc : 내림차순 (default)
                    <br />
                  </div>
                  <div className="table-cell left fg1">Number</div>
                </div>
              </div>
            </div>
            <div className="openAPI-table-container-header">
              <div className="openAPI-table-title">Header</div>
              <div className="openAPI-table">
                <div className="openAPI-table-head">
                  <div className="table-cell left fg2">Name</div>
                  <div className="table-cell left fg4">Info</div>
                  <div className="table-cell left fg1">Type</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">currency</div>
                  <div className="table-cell left fg4">Currency 코드</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">state</div>
                  <div className="table-cell left fg4">
                    출금 상태
                    <br />
                    - submitting : 처리 중
                    <br />
                    - submitted : 처리 완료
                    <br />
                    - almost_accepted : 출금대기중
                    <br />
                    - rejected : 거부
                    <br />
                    - accepted : 승인됨
                    <br />
                    - processing : 처리 중
                    <br />
                    - done : 완료
                    <br />
                    - canceled : 취소됨
                    <br />
                  </div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">uuids</div>
                  <div className="table-cell left fg4">출금 UUID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">txids</div>
                  <div className="table-cell left fg4">출금 TXID의 목록</div>
                  <div className="table-cell left fg1">Array</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">limit</div>
                  <div className="table-cell left fg4">개수 제한 (default: 100, max: 100)</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">page</div>
                  <div className="table-cell left fg4">페이지 수, default: 1</div>
                  <div className="table-cell left fg1">Number</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">order_by</div>
                  <div className="table-cell left fg4">
                    정렬
                    <br />
                    - asc : 오름차순
                    <br />
                    - desc : 내림차순 (default)
                    <br />
                  </div>
                  <div className="table-cell left fg1">Number</div>
                </div>
              </div>
            </div>
            <div className="openAPI-table-container-responses-code">
              <div className="openAPI-table-title">Responses Code</div>
              <div className="openAPI-table">
                <div className="openAPI-table-head">
                  <div className="table-cell left fg2">Name</div>
                  <div className="table-cell left fg4">Info</div>
                  <div className="table-cell left fg1">Type</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    <div className="code-sign-success" />
                    201
                  </div>
                  <div className="table-cell left fg4">성공</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    <div className="code-sign-success" />
                    202
                  </div>
                  <div className="table-cell left fg4">성공</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    <div className="code-sign-error" />
                    401
                  </div>
                  <div className="table-cell left fg4">에러</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    <div className="code-sign-error" />
                    402
                  </div>
                  <div className="table-cell left fg4">에러</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    <div className="code-sign-error" />
                    403
                  </div>
                  <div className="table-cell left fg4">에러</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    <div className="code-sign-error" />
                    404
                  </div>
                  <div className="table-cell left fg4">에러</div>
                  <div className="table-cell left fg1">String</div>
                </div>
                <div className="openAPI-table-body">
                  <div className="table-cell left fg2">
                    <div className="code-sign-error" />
                    405
                  </div>
                  <div className="table-cell left fg4">에러</div>
                  <div className="table-cell left fg1">String</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default OpenAPI;
