import React, { useEffect } from 'react';

import Nav from 'components/nav';
import Footer from 'components/footer';
import MobileMenuDrawer from 'components/mobile_menu_drawer';

import 'stylesheets/container.scss';

// eslint-disable-next-line react/prop-types
function Container({ children, paddingTop = 65, backgroundColor = '#f2f2f5' }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-wrapper" style={{ backgroundColor }}>
      <MobileMenuDrawer />
      <div className="container-nav-wrapper">
        <Nav />
      </div>
      <div className="container-children-wrapper" style={{ paddingTop: `${paddingTop}px` }}>
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default Container;
