import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Container from 'components/container';

import 'stylesheets/home.scss';

function Home() {
  // const showBlog = () => {
  //   alert('미공개 글입니다');
  // };

  useEffect(() => { }, []);

  return (
    <Container paddingTop={0}>
      <div className="home-title-wrapper">
        <div className="home-title-container">
          <div className="home-title-text-wrapper">
            <h1 className="home-title-text-content">
              스마트 오피스 시작!
              <br />
              Touch Call
            </h1>
            <Link to="/download">
              <div className="home-title-text-button">다운로드</div>
            </Link>
          </div>
          <div className="home-title-img-wrapper">
            <img
              id="home-title-img"
              className="home-title-img"
              src="/img/home-title-img.png"
              alt="터치콜 앱"
            />
          </div>
        </div>
      </div>
      <div className="home-overview-wrapper">
        <div className="home-overview-container">
          <div className="home-overview-title-wrapper">
            <h2 className="home-overview-title">
              터치콜은
              <br />
              내선번호를 모바일과 PC에서
              <br />
              이용 가능한 서비스입니다.
            </h2>
          </div>
          <img className="home-overview-img" src="/img/overview.png" alt="터치콜 앱" />
        </div>
      </div>
      <div className="home-intro1-wrapper">
        <div className="home-intro1-container">
          <div className="home-intro1-section1">
            <div className="home-intro1-text">
              <h2>
                밖에 있어도 업무전화를
                <br />
                놓칠 걱정이 없습니다
              </h2>
              <h3>중요한 전화를 기다리느라 자리에 얽매여 기다릴 필요가 없습니다.</h3>
            </div>
            <div className="home-intro1-img-wrapper">
              <img className="home-intro1-img" src="/img/introduce1-1.png" alt="통화 화면" />
            </div>
          </div>
          <div className="home-intro1-section2">
            <div className="home-intro1-text">
              <h2>
                통화가 가능한 요일과
                <br />
                시간대를 설정하세요
              </h2>
              <h3>설정한 시간 외에는 내가 설정한 ARS 멘트를 들려줍니다.</h3>
            </div>
            <div className="home-intro1-img-wrapper">
              <img
                className="home-intro1-img-reverse"
                src="/img/introduce1-2.png"
                alt="통화 화면"
              />
            </div>
          </div>
          <div className="home-intro1-section3">
            <div className="home-intro1-text">
              <h2>
                메세지로 효과적으로
                <br />
                소통하세요
              </h2>
              <h3>사진과 업무자료, 외부링크 등을 메세지에서 한 번에 주고 받으세요.</h3>
            </div>
            <div className="home-intro1-img-wrapper">
              <img className="home-intro1-img" src="/img/introduce1-3.png" alt="통화 화면" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-intro2-wrapper">
        <div className="home-intro2-container">
          <div className="home-intro2-section1">
            <div className="home-intro2-text">
              <h2>
                녹음된 음성을 텍스트로
                <br />
                확인하세요
              </h2>
              <h3>
                통화내용을 텍스트로 다시 한 번<br />
                확인 할 수 있어요
              </h3>
            </div>
            <div className="home-intro2-img-wrapper">
              <img className="home-intro2-img1" src="/img/introduce2-1.png" alt="통화 화면" />
              <img className="home-intro2-img2" src="/img/introduce2-2.png" alt="통화 화면" />
            </div>
          </div>
          <div className="home-intro2-section2">
            <div className="home-intro2-text">
              <h2>
                명함 촬영으로
                <br />
                간편하게
              </h2>
              <h3>
                귀찮게 직접 등록하지 않고 카메라로
                <br />
                간편하게 주소록에 등록할 수 있어요
              </h3>
            </div>
            <div className="home-intro2-img-wrapper">
              <img className="home-intro2-img3" src="/img/introduce2-3.png" alt="통화 화면" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-recommend-wrapper">
        <div className="home-recommend-container">
          <div>
            <h2>이런 고객님께 추천드려요</h2>
          </div>
          <img className="home-recommend-img" src="/img/recommend.jpg" alt="통화하는 사람" />
          <div className="home-recommend-text-wrapper">
            <div className="home-recommend-text-container-left">
              <div className="home-recommend-text">
                <h3 className="w500">스마트 오피스, 재택근무를 도입한 기업</h3>
                <p>
                  비대면 근무 및 재택근무 상황에서 자유로운 좌석 배치를 실현하고 재택근무 효율을
                  향상
                </p>
              </div>
              <div className="home-recommend-text">
                <h3 className="w500">임직원의 워라밸 추구하는 기업</h3>
                <p>
                  MZ 세대의 다양한 욕구와 사회의 변화에 따라 임직원의 일과 삶의 균형을 적극적으로
                  관리하는 기업이 인재를 더 많이 확보할 수 있음
                </p>
              </div>
              <div className="home-recommend-text">
                <h3 className="w500">통화 내용을 녹취, 문자 기록으로 관리가 필요한 기업</h3>
                <p>
                  TouchCall에서 전화를 하면 소중한 통화를 녹음하고, 놓치지 않고 문자로 기록 관리
                </p>
              </div>
            </div>
            <div className="home-recommend-text-container-right">
              <div className="home-recommend-text">
                <h3 className="w500">영업, 외근이 많은 기업</h3>
                <p>
                  회사번호로 걸려오는 전화를 놓치지 않는 것은 고객의 신뢰를 높이고 업무 효율과
                  시간을 절약해줍니다.
                </p>
              </div>
              <div className="home-recommend-text">
                <h3 className="w500">업무상 전화번호 관리가 많은 업종</h3>
                <p>
                  회사번호로 관리해야 되는 수 많은 거래처를 위하여 공용주소록과 개인업무 주소록을
                  제공
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-strongpoint-wrapper">
        <div className="home-strongpoint-container">
          <div>
            <h2>서비스 특장점</h2>
          </div>
          <div className="home-strongpoint-text-wrapper">
            <div className="home-strongpoint-text-section">
              <div className="home-strongpoint-text-title">
                <h3>업무효율 증대</h3>
              </div>
              <div className="home-strongpoint-text-script">
                <p>⦁ AnyWhere/AnyDevice 커뮤니케이션</p>
                <p>⦁ 업무 통화 시 개인번호 노출 최소화</p>
              </div>
            </div>
            <div className="home-strongpoint-text-section">
              <div className="home-strongpoint-text-title">
                <h3>편리한 운영</h3>
              </div>
              <div className="home-strongpoint-text-script">
                <p>⦁ 빠른 도입 및 구축비용 無! (App설치 후 바로 이용 가능)</p>
                <p>⦁ 사원 개인 스마트폰 활용 (이동 3사 모두 지원)</p>
              </div>
            </div>
            <div className="home-strongpoint-text-section">
              <div className="home-strongpoint-text-title">
                <h3>통신요금 절감</h3>
              </div>
              <div className="home-strongpoint-text-script">
                <p>⦁ 교환기 구축/이전 시 투자비용 절감(50% 이상)</p>
                <p>⦁ 유/무선 내선 : 무료통화</p>
                <p>⦁ 외부발신 : 인터넷 전화 요금</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Home;
