import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from 'pages/home';
import Download from 'pages/download';
import OpenAPI from 'pages/openAPI';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/download" element={<Download />} />
        <Route path="/openAPI" element={<OpenAPI />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
