/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {Link} from 'react-router-dom';

import 'stylesheets/mobile_menu_drawer.scss';

function MobileMenuDrawer() {
  const handleClose = () => {
    document.getElementById('menu-drawer').style.maxWidth = '0px';
  };

  return (
    <div className="mobile-menu-drawer-wrapper" id="menu-drawer">
      <div className="mobile-menu-drawer-header-row">
        <div>MENU</div>
        <img alt="close" src="/icon/menu-close.png" onClick={handleClose} />
      </div>
      <div className="mobile-menu-drawer-content-col">
        <Link to="/">
          <div className="mobile-menu-item">기능 설명</div>
        </Link>
        <Link to="/download">
          <div className="mobile-menu-item">다운로드</div>
        </Link>
        <Link to="/openAPI">
          <div className="mobile-menu-item">Open API</div>
        </Link>
      </div>
    </div>
  );
}

export default MobileMenuDrawer;
