import React, {useState} from 'react';

import Container from 'components/container';

import 'stylesheets/download.scss';

function Download() {
  const [accessToken, setAccessToken] = useState('');
  const [myNum, setMyNum] = useState('');
  const [counterNum, setCounterNum] = useState('');

  const showTab = (numb) => () => {
    const tabStatus = document.querySelectorAll('.download-tab-btn');
    const contentSet = document.querySelectorAll('.download-contentset');
    const selectedContent = contentSet[numb];
    for (let i = 0; i < tabStatus.length; i += 1) {
      tabStatus[i].className = 'download-tab-btn inactive';
      contentSet[i].className = `download-contentset tab${numb}`;
    }
    tabStatus[numb].className = 'download-tab-btn active';
    selectedContent.className = `${selectedContent.className} selectedTab`;
  };

  const openManual = (manual) => () => window.open(manual);

  const demoClickToCall = async () => {
    try {
      if (accessToken.length === 0 || myNum.length === 0 || counterNum.length === 0) {
        alert('모든 항목을 채워주세요');
      } else {
        const res = await fetch('https://kyowon.tcall.io:18443/api/v1/call', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            caller_number: myNum,
            callee_number: counterNum,
          }),
        });

        const j = await res.json();
        console.log(j);
        alert('실행되었습니다');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container paddingTop={0}>
      <div className="download-wrapper">
        <div className="download-container">
          <div className="download-title-section">
            <h1 className="download-title">Downloads</h1>
            <div className="download-tab-wrapper">
              <div className="download-tab-btn active" onClick={showTab(0)}>
                교원
              </div>
              <div className="download-tab-btn inactive" onClick={showTab(1)}>
                기본
              </div>
            </div>
          </div>
          <div className="download-contentset-wrapper">
            <div className="download-contentset tab0 selectedTab">
              <div className="download-companyname">
                <img src="img/download-logo-kyowon.png" alt="kyowon 로고" />
                교원 프로그램
              </div>
              <div className="download-links">
                <div className="download-content">
                  <div className="download-icon-container">
                    <img
                      className="download-icon"
                      src="img/download-android.png"
                      alt="Android 아이콘"
                    />
                  </div>
                  <span className="download-content-name">Android</span>
                  <div className="download-buttonset">
                    <span>
                      <a
                        onClick={openManual(
                          'https://play.google.com/store/apps/details?id=com.nemoslab.touchcall.kyowon'
                        )}
                      >
                        설치파일 다운로드
                      </a>
                    </span>
                    <span>
                      <a
                        onClick={openManual(
                          'https://firebasestorage.googleapis.com/v0/b/touchcall2022.appspot.com/o/touchcall%2Fkyowon%2Fandroid%2F%EA%B5%90%EC%9B%90%ED%84%B0%EC%B9%98%EC%BD%9C_%EC%95%88%EB%93%9C%EB%A1%9C%EC%9D%B4%EB%93%9C_%EC%84%A4%EC%B9%98_%EB%A7%A4%EB%89%B4%EC%96%BC_v2.pdf?alt=media&token=9afb70da-31d7-4b3f-90fc-92a6b4daf544',
                        )}
                      >
                        매뉴얼 다운로드
                      </a>
                    </span>
                  </div>
                </div>
                <div className="download-content">
                  <div className="download-icon-container">
                    <img className="download-icon" src="img/download-iOS.png" alt="iOS 아이콘" />
                  </div>
                  <span className="download-content-name">iOS</span>
                  <div className="download-buttonset">
                    <span>
                      <a
                        onClick={() => {
                          window.open(
                            'https://apps.apple.com/app/%EC%BC%80%EC%9D%B4%EC%BD%9C-%EC%97%85%EB%AC%B4%EC%A0%84%ED%99%94/id1629800855',
                          );
                        }}
                      >
                        설치파일 다운로드
                      </a>
                    </span>
                    <span>
                      <a onClick={openManual(
                        'https://firebasestorage.googleapis.com/v0/b/touchcall2022.appspot.com/o/touchcall%2Fkyowon%2Fios%2F%E1%84%80%E1%85%AD%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%90%E1%85%A5%E1%84%8E%E1%85%B5%E1%84%8F%E1%85%A9%E1%86%AF_iOS_%E1%84%89%E1%85%A5%E1%86%AF%E1%84%8E%E1%85%B5_%E1%84%86%E1%85%A2%E1%84%82%E1%85%B2%E1%84%8B%E1%85%A5%E1%86%AF_v1%2C1.pdf?alt=media&token=cc721d0c-8890-43ac-9728-e7bfb064a837',
                      )}>매뉴얼 다운로드</a>
                    </span>
                  </div>
                </div>
                <div className="download-content">
                  <div className="download-icon-container">
                    <img
                      className="download-icon"
                      src="img/download-windows.png"
                      alt="Windows 아이콘"
                    />
                  </div>
                  <span className="download-content-name">Windows (Desktop)</span>
                  <div className="download-buttonset">
                    <span>
                      <a
                        onClick={openManual(
                          'https://storage.googleapis.com/touchcall2022.appspot.com/touchcall/kyowon/windows/latest/kcall_installer.exe')}
                      >
                        설치파일 다운로드
                      </a>
                    </span>
                    {/* <span>
                      <a
                        onClick={openManual(
                          'https://firebasestorage.googleapis.com/v0/b/touchcall2022.appspot.com/o/touchcall%2Fkyowon%2Fwindows%2FTCALL_Setup.zip?alt=media&token=0a5c8961-75c9-4127-80c6-4a60e16a60a4',
                        )}
                      >
                        설치파일 다운로드(win7)
                      </a>
                    </span> */}
                    <span>
                      <a
                        onClick={openManual(
                          'https://storage.googleapis.com/touchcall2022.appspot.com/touchcall/kyowon/windows/latest/KCall_%EC%84%A4%EC%B9%98_%EC%82%AC%EC%9A%A9_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
                        )}
                      >
                        매뉴얼 다운로드
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="clicktocall-wrapper">
                <div className="label">클릭 투 콜 데모</div>
                <div className="row">
                  <input
                    placeholder="유저 보안토큰"
                    value={accessToken}
                    onChange={({target: {value}}) => setAccessToken(value)}
                  />
                  <input
                    placeholder="나의 번호 (내선)"
                    value={myNum}
                    onChange={({target: {value}}) => setMyNum(value)}
                  />
                  <input
                    placeholder="상대방 번호"
                    value={counterNum}
                    onChange={({target: {value}}) => setCounterNum(value)}
                  />
                  <button type="button" onClick={demoClickToCall}>
                    전화걸기
                  </button>
                </div>
              </div>
            </div>
            <div className="download-contentset tab1">
              <div className="download-companyname">기본 프로그램</div>
              <div className="download-links">
                <div className="download-content">
                  <div className="download-icon-container">
                    <img
                      className="download-icon"
                      src="img/download-android.png"
                      alt="Android 아이콘"
                    />
                  </div>
                  <span className="download-content-name">Android</span>
                  <div className="download-buttonset">
                    <span>
                      <a
                        onClick={openManual(
                          "https://firebasestorage.googleapis.com/v0/b/touchcall2022.appspot.com/o/touchcall%2Fdefault%2Fandroid%2Ftouchcall-demo-v1.0.3.apk?alt=media&token=803d9660-7bc5-4740-9dc7-a29744e93cff",
                        )}
                      >
                        설치파일 다운로드
                      </a>
                    </span>
                    <span>
                      <a onClick={() => alert('준비중입니다')}>메뉴얼 다운로드</a>
                    </span>
                  </div>
                </div>
                <div className="download-content">
                  <div className="download-icon-container">
                    <img className="download-icon" src="img/download-iOS.png" alt="iOS 아이콘" />
                  </div>
                  <span className="download-content-name">iOS</span>
                  <div className="download-buttonset">
                    <span>
                      <a
                        onClick={() => {
                          window.open(
                            'https://apps.apple.com/kr/app/%ED%84%B0%EC%B9%98%EC%BD%9C-%EC%97%85%EB%AC%B4%EC%A0%84%ED%99%94/id1626786753',
                          );
                        }}
                      >
                        설치파일 다운로드
                      </a>
                    </span>
                    <span>
                      <a onClick={() => alert('준비중입니다')}>메뉴얼 다운로드</a>
                    </span>
                  </div>
                </div>
                <div className="download-content">
                  <div className="download-icon-container">
                    <img
                      className="download-icon"
                      src="img/download-windows.png"
                      alt="Windows 아이콘"
                    />
                  </div>
                  <span className="download-content-name">Windows (Desktop)</span>
                  <div className="download-buttonset">
                    <span>
                      <a
                        onClick={openManual(
                          'https://firebasestorage.googleapis.com/v0/b/touchcall2022.appspot.com/o/touchcall%2Fdefault%2Fwindows%2Ftouchcall_installer_1.0.1.zip?alt=media&token=ee526f05-f8f0-4180-a49a-58f0fb669c30',
                        )}
                      >
                        설치파일 다운로드
                      </a>
                    </span>
                    <span>
                      <a onClick={() => alert('준비중입니다')}>메뉴얼 다운로드</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img className="download-img" src="img/overview.png" alt="터치콜 모바일 및 PC" />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Download;
