/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

import 'stylesheets/nav.scss';

const getNavItemClass = (currentPath, targetPath) => {
  return currentPath === targetPath ? 'nav-item-active' : 'nav-item';
};

// eslint-disable-next-line react/prop-types
function Nav({isScrollTop}) {
  const location = useLocation();
  const {pathname} = location;

  const openMenuDrawer = () => {
    document.getElementById('menu-drawer').style.maxWidth = '100vw';
  };

  return (
    <div
      className="nav-wrapper"
      style={{backgroundColor: isScrollTop ? 'rgba(255,255,255, 1)' : '#fff'}}
    >
      <div className="nav-container">
        <Link to="/">
          <img className="nav-logo" src="/img/nav-logo.png" alt="logo" />
        </Link>
        <div className="nav-row">
          <Link to="/">
            <div className={getNavItemClass(pathname, '/')}>기능 설명</div>
          </Link>
          <Link to="/download">
            <div className={getNavItemClass(pathname, '/download')}>다운로드</div>
          </Link>
          <Link to="/openAPI">
            <div className={getNavItemClass(pathname, '/openAPI')}>Open API</div>
          </Link>
        </div>
        <img
          alt="nav-menu"
          src="/icon/nav-menu.png"
          className="nav-mobile-menu-button"
          onClick={openMenuDrawer}
        />
      </div>
    </div>
  );
}

export default Nav;
