import React from 'react';

import 'stylesheets/footer.scss';

function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="text-1">네모스랩 주식회사</div>
        <div className="footer-row">
          <div className="footer-item-wrapper">
            <div className="text-1">사업자등록번호</div>
            <div className="text-2">771-88-02485</div>
          </div>
          <div className="footer-item-wrapper">
            <div className="text-1">대표이사</div>
            <div className="text-2">신동인</div>
          </div>
          <div className="footer-item-wrapper">
            <div className="text-1">이메일</div>
            <div className="text-2">info@nemoslab.com</div>
          </div>
        </div>
        <div className="footer-item-wrapper">
          <div className="text-1 footer-address">주소</div>
          <div className="text-2">
            경기도 안양시 동안구 시민대로 230, D동 5470호(관양동, 평촌아크로타워)
          </div>
        </div>
        <div className="footer-copyright">Copyright © Nemoslab Co., Ltd. All Rights Reserved.</div>
      </div>
    </div>
  );
}

export default Footer;
